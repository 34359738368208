import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { OverlayTrigger, Popover } from 'react-bootstrap'

import { motion } from "framer-motion"
import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from 'utils/constants'

import { Actions as AutoPayActions } from 'redux/features/banking/autopay'
import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'

import FilterButton from 'components/common/FilterButton'
import Footer from 'components/common/Footer'
import Navigation, { NavColor } from 'components/navbar/Navigation'
import Spinner from 'components/common/Spinner'

import WireTransfer from 'components/pages/Funding/AccountInfo'
import AutoFund from 'components/pages/Funding/AutoFund'
import DebitPayment from 'components/pages/Funding/Payment/DebitPayment'
import Statements from 'components/pages/Funding/Statements'
import PeerToPeer from 'components/pages/Funding/TaekusPay'
import TransferActivity from 'components/pages/Funding/TransferActivity'

import { fadeInOutMotionProps } from 'styles/motionConstants'
import styles from 'styles/styles'

import { ReactComponent as Reload } from "assets/svg/Reload.svg";

const SIDEBAR_WIDTH = 240
const CONTENT_WIDTH = 890

enum SidebarTab {
    Payment='Make a Transfer',
    Statements='Statements',
    AutoFund='Auto Reload',
    AccountInfo='Account Info',
    TransferActivity='Transfer Activity',
    PeerToPeer='TaekusPay'
}

const getSelectedTabFromURL = (tab?: string) => {
    switch (tab) {
        case 'statements':
            return SidebarTab.Statements
        case 'autoreload':
            return SidebarTab.AutoFund
        case 'accountinfo':
            return SidebarTab.AccountInfo
        case 'transferactivity':
            return SidebarTab.TransferActivity
        case 'taekuspay':
            return SidebarTab.PeerToPeer
        case 'payment':
        default:
            return SidebarTab.Payment
    }
}

const Funding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { tab } = useParams<{ tab?: string }>();

    // Redux state
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const banking = useSelector((state: any) => state.banking)
    const autopay = useSelector((state: any) => state.autopay)
    const transfers = useSelector((state: any) => state.transfers.transfers)
    const shouldUpdateLinkedAccounts = useSelector((state: any) => state.linkedAccounts.shouldUpdateLinkedAccounts)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    
    // Local state
    const [selectedSidebarTab, setSelectedSidebarTab] = useState(getSelectedTabFromURL(tab))

    const pageBody = useRef(null)

    // todo: remove after 6/24/2024
    // const isACHRedesignEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.ACHRedesign)
    const balanceAmount = Number(banking?.account.balance.availableBalance)?.toLocaleString(Locale.English, USDCurrencyOptions)
    const monthlySpendAmount = banking?.account.mtdSpending?.toLocaleString(Locale.English, USDCurrencyOptions)
    const transferAmount = banking.account.mtdTransfers?.toLocaleString(Locale.English, USDCurrencyOptions)
    const creditLimitAmount = banking.account.transferLimit?.toLocaleString(Locale.English, USDCurrencyOptions)

    const pendingTransferCount = transfers.reduce((acc: number, current: any) => acc + (current.status === 'Pending' ? 1 : 0), 0)
    const pendingTransferAmount = transfers.reduce((acc: number, current: any) => acc + (current.status === 'Pending' ? current.amount : 0), 0)

    const sidebarTabs = [
        SidebarTab.Payment,
        SidebarTab.Statements,
        SidebarTab.AutoFund,
        SidebarTab.AccountInfo,
        SidebarTab.TransferActivity,
        ...(currentUser.isDisabledFraud ? [] : [SidebarTab.PeerToPeer]),
    ]

    // On component render, fetch linked accounts
    useEffect(() => {
        dispatch(LinkedAccountActions.getLinkedAccounts())
        dispatch(LinkedAccountActions.getInternalAccounts())
    }, [dispatch])

    // If Plaid Modal creates or verifies an account, check if we should re-fetch linked accounts
    useEffect(() => {
        if (shouldUpdateLinkedAccounts){
            dispatch(LinkedAccountActions.getLinkedAccounts())
        }
    }, [shouldUpdateLinkedAccounts, dispatch])

    // fetch Autopay for the sidebar display
    useEffect(() => {
        if (banking.account.uuid){
            dispatch(AutoPayActions.fetchAutopay({ cardAccountUuid: banking.account.uuid }))
        }
    }, [banking, dispatch])

    const getTabContent = () => {
        switch (selectedSidebarTab) {
            case SidebarTab.TransferActivity:
                return <TransferActivity/>
            case SidebarTab.Statements:
                return <Statements/>
            case SidebarTab.AutoFund:
                return <AutoFund/>
            case SidebarTab.AccountInfo:
                return <WireTransfer/>
            case SidebarTab.PeerToPeer:
                return <PeerToPeer/>
            case SidebarTab.Payment:
            default:
                return <DebitPayment/>
        }
    }

    const mapSidebarTabToItem = (sidebarTab: SidebarTab) => {
        const selectSidebarItem = () => {
            setSelectedSidebarTab(sidebarTab);
            history.push(`/funding/${sidebarTab.replaceAll(' ', '').toLowerCase()}`);
            (pageBody.current as any).scrollTo!({ top: 0, behavior: 'smooth' })
        }

        return <SidebarItem 
            onClick={selectSidebarItem}
            isSelected={sidebarTab === selectedSidebarTab}
            key={`tab:${sidebarTab}`}
        >
            {sidebarTab}
        </SidebarItem>
    }

    return <Container ref={pageBody}>
        <div>
            <Inner>
                <Navigation color={NavColor.Black}/>
                <Details>
                    <Flex>
                        <Label>Balance</Label>
                        {banking.isLoading ? <SmallSpinnerContainer>
                            <Spinner/>
                        </SmallSpinnerContainer> : <Value>
                            {balanceAmount}
                        </Value>}
                    </Flex>
                    <Flex>
                        <Label>Spend this Month</Label>
                        {banking.isLoading ? <SmallSpinnerContainer>
                            <Spinner/>
                        </SmallSpinnerContainer> : <Value>
                            {monthlySpendAmount}
                        </Value>}
                    </Flex>
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={!banking.isLoading ? <CustomPopover id='popover'>
                            <div><strong>Used:</strong> {transferAmount} / {creditLimitAmount}</div>
                            <div><strong>Remaining:</strong> {(banking.account.transferLimit - banking.account.mtdTransfers).toLocaleString(Locale.English, USDCurrencyOptions)}</div>
                        </CustomPopover> : <div/>}
                    >
                        <Flex>
                            <Label>ACH Transfer Limit</Label>
                            {banking.isLoading ? <SmallSpinnerContainer>
                                <Spinner/>
                            </SmallSpinnerContainer> : <Value>
                                {creditLimitAmount}
                            </Value>}
                        </Flex>
                    </OverlayTrigger>
                    <AutoReloadContainer>
                        <AutoReloadLabel>
                            <div className='d-flex align-items-center'>
                                <div>Auto reload</div>
                                <ReloadIconWrapper>
                                    <StyledReload/>
                                </ReloadIconWrapper>
                            </div>
                            <div>{autopay.workingCopy.active ? <GreenText>ON</GreenText> : 'OFF'}</div>
                        </AutoReloadLabel>
                    </AutoReloadContainer>
                </Details>
                <Content>
                    {isMobile ? <div>
                        <MobileTitle>Manage Funds</MobileTitle>
                        <FilterButtonContainer>
                            {sidebarTabs.map(tab => <FilterButton 
                                onClick={() => setSelectedSidebarTab(tab)}
                                selected={selectedSidebarTab === tab}
                            >
                                {tab}
                            </FilterButton>)}
                        </FilterButtonContainer>
                    </div> : <Sidebar>
                        {sidebarTabs.map(mapSidebarTabToItem)}
                    </Sidebar>}
                    <Funds key={selectedSidebarTab} {...fadeInOutMotionProps}>
                        {!isMobile && <Title>
                            {selectedSidebarTab}
                            {selectedSidebarTab === SidebarTab.TransferActivity && pendingTransferCount > 0 && <PendingTransferText>{pendingTransferCount} transfers pending ({(pendingTransferAmount>0 ? "+" : "") + pendingTransferAmount.toLocaleString(Locale.English, USDCurrencyOptions)})</PendingTransferText>}
                        </Title>}
                        {getTabContent()}
                    </Funds>
                </Content>
            </Inner>
            <Footer/>
        </div>
    </Container>
}

const Inner = styled.div`
    ${styles.MediaQueries.Mobile} {
        height: min-content;
        min-height: 100vh;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`

const PendingTransferText = styled.div`
    font-size: ${styles.Font.Size.Small};
    margin-left: ${styles.Spacing.XS};
    margin-bottom: 6px;
    opacity: 0.5;
`

const CustomPopover = styled(Popover)`
    padding: ${styles.Spacing.XS};
    background-color: ${styles.Color.TaekusPurple};
    color: white;
    white-space: nowrap;
    .arrow { display: none; }
`

const AutoReloadContainer = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    margin: 0;
    ${styles.MediaQueries.Mobile} {
        padding-bottom: ${styles.Spacing.XS};
    }
`

const AutoReloadLabel = styled.div`
    display: flex;
    justify-content: space-between;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        width: 120px;
        margin-right: ${styles.Spacing.S};
    }
`

const StyledReload = styled(Reload)`
    width: 12px;
    height: 12px;
`

const ReloadIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
`

const GreenText = styled.span`
    color: #008e00;
`

const SmallSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
`

type SidebarItemProps = {
    isSelected: boolean;
}

const SidebarItem = styled.div<SidebarItemProps>`
    cursor: pointer;
    color: ${props => props.isSelected ? styles.Color.TaekusPurple : styles.Color.Black};
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        justify-content: space-between;
    }
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.M};
    }
`

const Title = styled(motion.div)`
    display: flex;
    align-items: end;
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 32px;
    border-bottom: 1px solid ${styles.Color.Black};
`

const MobileTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
`

const Container = styled.div`
    height: 100%;
    background-size: cover;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    color: ${styles.Color.Black};
    ${styles.Scrollbar.defaultScrollbarStyles}
    ${styles.MediaQueries.Mobile} {
        width: 100vw;
        overflow-x: hidden;
    }
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    min-width: ${SIDEBAR_WIDTH}px;
    padding-left: ${styles.Spacing.M};
    padding-right: ${styles.Spacing.M};
    padding-top: 48px;
`

const Label = styled.div`
    margin-right: ${styles.Spacing.XXS};
`

const Value = styled.div`
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: ${styles.Color.TaekusBlue};
`

const FilterButtonContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-x: auto;
    height: 50px;
    margin: ${styles.Spacing.S} 0;
    padding: 0 ${styles.Spacing.S} ${styles.Spacing.XS};
    mask-image: linear-gradient(to right, transparent, black ${styles.Spacing.S}, black calc(100% - ${styles.Spacing.S}), transparent 100%);
    ${styles.Scrollbar.transparent}
`

const Details = styled.div`
    display: flex;
    justify-content: end;
    white-space: nowrap;
    ${styles.MediaQueries.Desktop} {
        min-width: ${SIDEBAR_WIDTH + CONTENT_WIDTH + 40}px;
        align-items: center;
        margin-top: 30px;
        margin-bottom: ${styles.Spacing.XS};
        margin-right: ${styles.Spacing.M};
        height: 24px;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        margin: 0 ${styles.Spacing.S};
    }
`

const Content = styled.div`
    flex: 1;
    display: flex;
    ${styles.MediaQueries.Desktop} {
        padding-right: ${styles.Spacing.M};
        min-height: 100vh;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const Funds = styled(motion.div)`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Desktop} {
        min-width: ${CONTENT_WIDTH}px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 ${styles.Spacing.S};
    }
`

export default Funding