import React from "react"
import { useSelector } from "react-redux"

import { motion } from "framer-motion"
import styled from "styled-components"

import { CardAccountType } from "types/CardAccount"
import { LinkedAccount } from "types/LinkedAccount"

import { Locale, USDCurrencyOptions } from "utils/constants"

import Spinner from "components/common/Spinner"

import { getAccountName } from "components/pages/Funding/Payment/util"
import PlaidModal from "components/pages/Funding/PlaidModal"

import styles from "styles/styles"

const messages = {
    AccountLabel: {
        Credit: 'Credit',
        Debit: 'Debit',
        External: 'External account'
    },
    AddAccount: '+ Add Account',
    Unavailable: 'Unavailable'
}

type AccountsDropdownProps = {
    accounts: LinkedAccount[],
    onClick: (account: any) => void
}

const AccountsDropdown = (props: AccountsDropdownProps) => {
    const { accounts, onClick } = props;

    const isFetchingInternalAccounts = useSelector((state: any) => state.linkedAccounts.isFetchingInternalAccounts)
    const isFetchingExternalAccounts = useSelector((state: any) => state.linkedAccounts.isFetchingExternalAccounts)

    const isLoadingAccounts = isFetchingExternalAccounts || isFetchingInternalAccounts

    const getAccountTypeLabel = (account: LinkedAccount) => {
        if (!account.isInternalAccount) {
            return messages.AccountLabel.External
        }
        return account.type === CardAccountType.Credit ? messages.AccountLabel.Credit :  messages.AccountLabel.Debit
    }

    const getAccountSubtitle = (account: LinkedAccount) => {
        if (account.isInternalAccount) {
            return <span>{account.availableBalance?.toLocaleString(Locale.English, USDCurrencyOptions)}</span>
        } else if (!account.verified || !account.isDebitReady) {
            return <UnavailableText>{messages.Unavailable}</UnavailableText>
        }
    }

    const stopPropagation = (ev: React.MouseEvent) => {
        ev.stopPropagation()
    }

    const mapAccountToDropdownItem = (account: LinkedAccount, index: number) => {
        const handleAccountClick = (ev: React.MouseEvent) => {
            if (!account.isInternalAccount && (!account.verified || !account.isDebitReady)) {
                ev.stopPropagation()
            } else {
                onClick(account)
            }
        }

        return <AccountDropdownItem 
            data-testid={`accountOption-${index}`} 
            onClick={handleAccountClick}
            key={`accountOption-${index}`} 
            isAccountDisabled={!account.isInternalAccount && (!account.verified || !account.isDebitReady)}
        >
            <AccountDropdownTitle isAccountDisabled={!account.isInternalAccount && (!account.verified || !account.isDebitReady)}>{getAccountName(account)} (...{account.accountLast4})</AccountDropdownTitle>
            <AccountDropdownDescription>
                <span>{getAccountTypeLabel(account)}</span>
                {(!account.isInternalAccount && !account.verified) ? <div onClick={stopPropagation}>
                    <PlaidModal linkedAccount={account}>
                        <VerifyLink>Verify</VerifyLink>
                    </PlaidModal>
                </div> : getAccountSubtitle(account)}
            </AccountDropdownDescription>
            <LineBreakContainer>
                <LineBreak/>
            </LineBreakContainer>
        </AccountDropdownItem>
    }

    return <Container>
        {isLoadingAccounts && <AccountDropdownItem isAccountDisabled onClick={stopPropagation}>
            <div className="d-flex justify-content-center align-items-center">
                <Spinner size={20}/>
            </div>
            <LineBreakContainer>
                <LineBreak/>
            </LineBreakContainer>
        </AccountDropdownItem>}
        {[...accounts].map(mapAccountToDropdownItem)}
        <div className="d-flex" onClick={stopPropagation}>
            <PlaidModal style={{flex: 1}}>
                <AddAccountItem>{messages.AddAccount}</AddAccountItem>
            </PlaidModal>
        </div>
    </Container>
}

const UnavailableText = styled.span`
    color: ${styles.Color.FailureRed};
`

const VerifyLink = styled.div`
    color: ${styles.Color.TaekusPurple};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const LineBreakContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 16px;
`

const LineBreak = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${styles.Color.Grey};
`

const AddAccountItem = styled.div`
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    padding: 16px 20px;
    cursor: pointer;
    ${styles.Animation.transitionStyles}
    &:hover {
        background-color: #F2ECF1;
    }
`

const AccountDropdownDescription = styled.div`
    color: #767676;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.14px;
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

type AccountProps = {
    isAccountDisabled?: boolean,
}

const AccountDropdownTitle = styled.div<AccountProps>`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    ${props => props.isAccountDisabled && `opacity: 0.5;`}
`

const AccountDropdownItem = styled.div<AccountProps>`
    display: flex;
    flex-direction: column;
    height: 72px;
    padding: 16px 20px;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
    ${props => !props.isAccountDisabled && `
        cursor: pointer;
        &:hover {
            background-color: rgba(1,2,2,0.05);
        }
    `}
`

const Container = styled(motion.div)`
    position: absolute;
    z-index: 9999;
    // translate 3d to fix iOS clipping issue
    -webkit-transform: translate3d(0,0,1px);
    transform: translate3d(0,0,1px);
    max-height: ${76 * 5}px;
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
    top: 100%;
    ${styles.MediaQueries.Desktop} {
        width: 353px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    max-width: 100%;
    background-color: ${styles.Color.White};
    border: 1px solid ${styles.Color.Grey};
`

export default AccountsDropdown