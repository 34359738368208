import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import { memoize } from "lodash"
import moment from "moment"

import { OverlayTrigger } from "react-bootstrap"

import { motion } from 'framer-motion'
import styled from "styled-components"

import { Actions as FlightSearchActions } from 'redux/features/flightSearch'
import { Actions as FlightBookActions } from 'redux/features/flightBook'

import airlines from "utils/airlines"

import DatePicker from "components/common/DatePicker/DatePicker"
import { FlightLocationDropdownStyleTypes } from "components/common/FlightLocationDropdown/constants"
import FlightLocationDropdown from "components/common/FlightLocationDropdown/FlightLocationDropdown"
import Select from "components/common/Select/Select"
import selectStyles from "components/common/Select/selectStyles"
import Tooltip from "components/common/Tooltip"
import Navigation, { NavColor } from "components/navbar/Navigation"

import {
    ItinerarySearchDataKeys,
    SearchOptions,
    TripType,
    defaultSearchOptions,
    enumeratedSearchOptions,
    SearchSource
} from "components/pages/Rewards/constants"
import FlightSearchCalendar from "components/pages/Rewards/Flights/FlightSearch/FlightSearchCalendar"
import Itineraries from "components/pages/Rewards/Flights/FlightSearch/Itineraries"
import SeatMapModal from "components/pages/Rewards/Flights/FlightSearch/SeatMapModal"
import { getSearchOptionsFromLocalStorage, updateSearchOptions } from "components/pages/Rewards/utils"

import { ReactComponent as Reverse } from "assets/svg/Reverse.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as PlaneArrival } from "assets/svg/PlaneArrival.svg";
import { ReactComponent as PlaneDeparture } from "assets/svg/PlaneDeparture.svg";
import FlightSearchWs from "components/pages/Rewards/Flights/FlightSearchWs"

import styles from "styles/styles"

const Flights = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const isSeatMapModalOpen = useSelector((state: any) => state.flights.seatMap.isOpen)

    // Component state
    const [searchOptions, setSearchOptions] = useState<SearchOptions>(getSearchOptionsFromLocalStorage() || defaultSearchOptions)
    const [selectedItinerary, setSelectedItinerary] = useState<any[] | undefined>(searchOptions.tripType === TripType.RoundTrip ? [] : undefined)
    const [airlinesCache, setAirlinesCache] = useState({})
    const [connectionsCache, setConnectionsCache] = useState({})

    // Calendar Search
    const [isCalendarLoading, setIsCalendarLoading] = useState(false)
    const [calendarOptions, setCalendarOptions] = useState([])
    const [fetchedCalendarWeeks, setFetchedCalendarWeeks] = useState<number[]>([])
    const [erroredCalendarWeeks, setErroredCalendarWeeks] = useState<number[]>([])
    const [calendarSearchParams, setCalendarSearchParams] = useState({})
    
    // Itinerary/Award Search
    const [isItineraryLoading, setIsItineraryLoading] = useState(false)
    const [itineraryOptions, setItineraryOptions] = useState([])
    const [itinerarySearchData, setItinerarySearchData] = useState({})
    const [isItineraryError, setIsItineraryError] = useState(false)
    const [itinerarySearchParams, setItinerarySearchParams] = useState({})
    const [isAwardLoading, setIsAwardLoading] = useState(false)
    const [isAwardError, setIsAwardError] = useState(false)
    
    const calendarRequestIdToParams = useRef<any>({});
    const itineraryRequestIdToParams = useRef<any>({});
    const awardRequestIdToParams = useRef<any>({});
    const [awardSearchSessionId, setAwardSearchSessionId] = useState<string | null>(null)
    const [itinerarySearchSessionId, setItinerarySearchSessionId] = useState<string | null>(null)
    const [ndcSearchSessionId, setNdcSearchSessionId] = useState<string | null>(null)

    const [isNdcLoading, setIsNdcLoading] = useState(false)
    const [isNdcError, setIsNdcError] = useState(false)
    const ndcRequestIdToParams = useRef<any>({});

    const fetchItineraries = (params: any) => {
        setItineraryOptions([])
        setItinerarySearchData({})
        setIsItineraryLoading(false)
        setIsAwardLoading(false)
        setIsNdcLoading(false)
        setIsItineraryError(false)
        setIsAwardError(false)
        setIsNdcError(false)
        itineraryRequestIdToParams.current = {}
        awardRequestIdToParams.current = {}
        ndcRequestIdToParams.current = {}
        setItinerarySearchParams(params)
    }

    const fetchCalendar = (params: any, replace: boolean = false) => {
        if (replace) {
            setCalendarOptions([])
            setFetchedCalendarWeeks([])
            setErroredCalendarWeeks([])
            calendarRequestIdToParams.current = {}
        }
        setCalendarSearchParams(params)
    }

    const connectingAirportOptions = Object.keys(connectionsCache).map((airportCode: string) => ({ value: airportCode, label: (connectionsCache as any)[airportCode] }))
    const airlineOptions = Object.keys(airlinesCache).map((carrierKey: string) => ({ value: carrierKey, label: (airlinesCache as any)[carrierKey] }) ).sort((a: any, b: any) => a.label.localeCompare(b.label))

    const clearDepartureSelection = () => {
        setSelectedItinerary([])
    }

    const updateSearchOption = (key: keyof SearchOptions, value: any) => {
        const updatedSearchOptions = updateSearchOptions(key, value, searchOptions)
        // If switching from one-way to round trip, prompt for return date
        if (key === 'tripType' && value === TripType.RoundTrip) {
            setTimeout(() => {
                if (isMobile) {
                    // This causes an error, needs updated paradigm
                    // (document.getElementById('returnMobileDatePicker') as HTMLInputElement)?.showPicker()
                } else {
                    document.getElementById('datePicker')?.click()
                }
            }, 500) ;
        }

        if (['departureDate',
            'returnDate',
            'departureAirport',
            'arrivalAirport',
            'tripType',
            'numberOfPassengers',
            'ticketType',
            'maxNumberOfStops',
            'airlineOption',
            'travelClass',
            'isRefundable',
            'excludeNoCarryOn',
            'connectingAirport'].includes(key)) {
            // Only clear selection if there is actually something selected to avoid firing additional effects
            if (updatedSearchOptions.tripType === TripType.RoundTrip ? (selectedItinerary?.length || 0) > 0 : selectedItinerary) {
                setSelectedItinerary(updatedSearchOptions.tripType === TripType.RoundTrip ? [] : undefined)
            }
        }

        // Set airlineOption and connectingAirport to default values when departureAirport or arrivalAirport change
        if (['departureAirport', 'arrivalAirport'].includes(key)) {
            updatedSearchOptions.airlineOption = null;
            updatedSearchOptions.connectingAirport = null;
        }

        localStorage.setItem('searchOptions', JSON.stringify(updatedSearchOptions))
        setSearchOptions(updatedSearchOptions)
    }

    const createFlightOptionOnChange = memoize((searchOption: keyof SearchOptions) => {
        return (option: any) => {
            updateSearchOption(searchOption, option.value)
        }
    })

    const handleDepartureAirportChange = (departureAirport: any) => {
        updateSearchOption('departureAirport', departureAirport)
    }

    const handleArrivalAirportChange = (arrivalAirport: any) => {
        updateSearchOption('arrivalAirport', arrivalAirport)
    }

    const selectItinerary = (itineraryOption: any) => {
        if (searchOptions.tripType === TripType.RoundTrip) {
            setSelectedItinerary([...(selectedItinerary || [])!, itineraryOption])
        } else {
            setSelectedItinerary(itineraryOption)
        }
    }

    const swapAirports = () => {
        const departureCopy = { ...searchOptions.departureAirport };
        const arrivalCopy = { ...searchOptions.arrivalAirport };
        const updatedSearchOptions = {
            ...searchOptions,
            departureAirport: arrivalCopy,
            arrivalAirport: departureCopy
        }
        setSearchOptions(updatedSearchOptions)
        localStorage.setItem('searchOptions', JSON.stringify(updatedSearchOptions))
    }

    // Clear search options on unmount
    useEffect(() => {
        return () => { dispatch(FlightSearchActions.clearSearchOptions()) }
    }, [dispatch])

    const getSessionId = () => {
        const itinerary = selectedItinerary?.length === 2 ? selectedItinerary[0] : selectedItinerary
        if (itinerary?.cacheSource === 5) {
            return itinerarySearchSessionId
        }
        switch (itinerary?.searchSource) {
            case SearchSource.Award:
                return awardSearchSessionId
            case SearchSource.Airgateway:
                return ndcSearchSessionId
            default:
                return itinerarySearchSessionId
        }
    }

    /*
     *  Set itinerary and go to confirmation page when itinerary is full
     *
     *  If setting the departure date for a round trip flight, update the calendar month to the current return date
     */
    useEffect(() => {
        if (searchOptions.tripType === TripType.RoundTrip) {
            if (selectedItinerary?.length === 2) {
                dispatch(FlightSearchActions.updateSearchParams({ 
                    outboundItinerary: selectedItinerary[0],
                    inboundItinerary: selectedItinerary[1],
                    numPax: { value: Number(searchOptions.numberOfPassengers) },
                    sessionId: getSessionId()
                }))
                history.push('/rewards/confirm')
            }
        } else if (selectedItinerary) {
            dispatch(FlightSearchActions.updateSearchParams({ 
                outboundItinerary: selectedItinerary,
                inboundItinerary: null,
                numPax: { value: Number(searchOptions.numberOfPassengers) },
                sessionId: getSessionId()
            }))
            history.push('/rewards/confirm')
        }
    }, [selectedItinerary, dispatch]) // eslint-disable-line

    useEffect(() => {
        dispatch(FlightBookActions.clearPriceDetails())
    }, [dispatch])
    
    const handleDateChange = (date: string[]) => {
        if (searchOptions.tripType === TripType.RoundTrip) {
            if (searchOptions.tripType === TripType.RoundTrip ? (selectedItinerary?.length || 0) > 0 : selectedItinerary) {
                setSelectedItinerary(searchOptions.tripType === TripType.RoundTrip ? [] : undefined)
            }

            const departureDate = date[0] ? moment(date[0]) : undefined;
            const returnDate = date[1] ? moment(date[1]) : undefined

            const updatedSearchOptions = updateSearchOptions('returnDate', returnDate, updateSearchOptions('departureDate', departureDate, searchOptions))
            localStorage.setItem('searchOptions', JSON.stringify(updatedSearchOptions))
            setSearchOptions(updatedSearchOptions)
        } else {
            updateSearchOption('departureDate', moment(date))
        }
    }

    const setMobileDepartureDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const date = moment(ev.target.value)

        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, departureDate: date }))
        updateSearchOption('departureDate', date)
    }

    const setMobileReturnDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const date = moment(ev.target.value)

        localStorage.setItem('searchOptions', JSON.stringify({ ...searchOptions, returnDate: date }))
        updateSearchOption('returnDate', date)
    }

    useEffect(() => {
        if (ItinerarySearchDataKeys.Carriers in itinerarySearchData) {
            const cacheCopy = JSON.parse(JSON.stringify(airlinesCache))
            const airlineCarriers = itinerarySearchData[ItinerarySearchDataKeys.Carriers]
            Object.keys(airlineCarriers || {}).forEach((airlineKey: string) => {
                cacheCopy[airlineKey] = (airlineCarriers as any)[airlineKey];
            })
            setAirlinesCache(cacheCopy)
        }
        if (ItinerarySearchDataKeys.Airports in itinerarySearchData) {
            const cacheCopy = JSON.parse(JSON.stringify(connectionsCache))
            const connectingAirports = itinerarySearchData[ItinerarySearchDataKeys.Airports]
            Object.keys(connectingAirports || {}).forEach((connectionKey: string) => {
                cacheCopy[connectionKey] = (connectingAirports as any)[connectionKey];
            })
            setConnectionsCache(cacheCopy)
        }

    }, [itinerarySearchData]) // eslint-disable-line

    const diffInDays = searchOptions.returnDate?.diff(searchOptions.returnDate, 'days')
    useEffect(() => {
        setAirlinesCache({})
        setConnectionsCache({})
    }, [
        diffInDays,
        searchOptions.departureAirport,
        searchOptions.arrivalAirport,
        searchOptions.tripType,
        searchOptions.numberOfPassengers,
        searchOptions.ticketType,
        searchOptions.maxNumberOfStops,
        searchOptions.travelClass,
        searchOptions.isRefundable,
    ])

    return <Container>
        <Navigation color={NavColor.Black} />
        <OptionsHeader>
            <DateAndLocationRow>
                <div className='d-flex'>
                    {!isMobile && <PlaneIcon>
                        <PlaneDeparture fill={styles.Color.TaekusCream}/>
                    </PlaneIcon>}
                    <FlightLocationDropdownContainer>
                        <FlightLocationDropdown
                            value={searchOptions.departureAirport}
                            name="from"
                            id="departure"
                            placeholder="Departure Airport"
                            styleType={isMobile ? FlightLocationDropdownStyleTypes.MobileFlights : "red"}
                            changeHandler={handleDepartureAirportChange}
                            blacklist={[searchOptions.arrivalAirport?.value]}
                        />
                    </FlightLocationDropdownContainer>
                    <ReverseIcon onClick={swapAirports}>
                        <StyledReverseIcon/>
                    </ReverseIcon>
                    {!isMobile && <PlaneIcon>
                        <PlaneArrival fill={styles.Color.TaekusCream}/>
                    </PlaneIcon>}
                    <FlightLocationDropdownContainer>
                        <FlightLocationDropdown
                            value={searchOptions.arrivalAirport}
                            name="to"
                            id="destination"
                            placeholder="Destination Airport"
                            styleType={isMobile ? FlightLocationDropdownStyleTypes.MobileFlights : "red"}
                            changeHandler={handleArrivalAirportChange}
                            blacklist={[searchOptions.departureAirport?.value]}
                        />
                    </FlightLocationDropdownContainer>
                </div>
                <DatePickerContainer>
                    {isMobile ?
                    <div style={{ borderBottom: `1px solid ${styles.Color.GreyText}`, height: 'auto' }} className="d-flex justify-content-between align-items-center w-100">
                        <MobileDatePickerInput
                            type='date'
                            value={searchOptions.departureDate?.format('YYYY-MM-DD')}
                            onChange={setMobileDepartureDate}
                            min={searchOptions.departureDate?.format('YYYY-MM-DD')}
                            max={moment().add(335, 'days').format('YYYY-MM-DD')}
                        /> 
                        {searchOptions.tripType === TripType.RoundTrip && <>
                            <div>–</div>
                            <MobileDatePickerInput
                                id='returnMobileDatePicker'
                                type='date'
                                value={searchOptions.returnDate?.format('YYYY-MM-DD')}
                                onChange={setMobileReturnDate}
                                min={searchOptions.departureDate?.format('YYYY-MM-DD')}
                                max={moment().add(335, 'days').format('YYYY-MM-DD')}
                            />
                        </>}
                    </div> :
                    <DatePickerDropdownContainer>
                        <DatePickerTitle>Date</DatePickerTitle>
                        <DatePicker 
                            id='datePicker'
                            selected={searchOptions.departureDate}
                            startDate={searchOptions.departureDate}
                            endDate={searchOptions.tripType === TripType.RoundTrip ? searchOptions.returnDate : undefined}
                            onChange={handleDateChange}
                            customInput={<DatePickerInput>
                                <div>{`${searchOptions.departureDate && searchOptions.departureDate.format('MMMM D, YYYY')}  ${searchOptions.tripType === TripType.RoundTrip ? (searchOptions.returnDate ? `- ${searchOptions.returnDate?.format('MMMM D, YYYY')}` : '- ...') : ''}`}</div>
                                <CustomCaretDown fill='white'/>
                            </DatePickerInput>}
                            minDate={moment().startOf('day')}
                            maxDate={moment().add(335, 'days')}
                            selectsRange={searchOptions.tripType === TripType.RoundTrip}
                        />
                    </DatePickerDropdownContainer>}
                </DatePickerContainer>
            </DateAndLocationRow>
            <FadeOverlay/>
            <ScrollableOptionsWrapper>
                <OptionsRow>
                    <Select
                        value={enumeratedSearchOptions.tripType.find((option: any) => option.value === searchOptions.tripType) as any}
                        onChange={createFlightOptionOnChange('tripType')}
                        options={enumeratedSearchOptions.tripType}
                        dropdownIndicator={<CustomCaret fill='white'/>}
                        styleType={selectStyles.flightSearch}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                    <Select
                        value={enumeratedSearchOptions.numberOfPassengers.find((option: any) => option.value === searchOptions.numberOfPassengers) as any}
                        onChange={createFlightOptionOnChange('numberOfPassengers')}
                        options={enumeratedSearchOptions.numberOfPassengers}
                        dropdownIndicator={<CustomCaret fill='white'/>}
                        styleType={selectStyles.flightSearch}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                    <Select
                        value={enumeratedSearchOptions.ticketType.find((option: any) => option.value === searchOptions.ticketType) as any}
                        onChange={createFlightOptionOnChange('ticketType')}
                        options={enumeratedSearchOptions.ticketType}
                        dropdownIndicator={<CustomCaret fill='white'/>}
                        styleType={selectStyles.flightSearch}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                    <Select
                        value={enumeratedSearchOptions.maxNumberOfStops.find((option: any) => option.value === searchOptions.maxNumberOfStops) as any}
                        onChange={createFlightOptionOnChange('maxNumberOfStops')}
                        options={enumeratedSearchOptions.maxNumberOfStops}
                        dropdownIndicator={<CustomCaret fill='white'/>}
                        styleType={selectStyles.flightSearch}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                    <Select
                        value={enumeratedSearchOptions.isRefundable.find((option: any) => option.value === searchOptions.isRefundable) as any}
                        onChange={createFlightOptionOnChange('isRefundable')}
                        options={enumeratedSearchOptions.isRefundable}
                        dropdownIndicator={<CustomCaret fill='white'/>}
                        styleType={selectStyles.flightSearch}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={searchOptions.connectingAirport ? <Tooltip id='tooltip'>
                            <div>You cannot filter by airline and connecting airport at the same time.</div>
                        </Tooltip> : <div/>}
                    >
                        <span>
                            <Select
                                isDisabled={!!searchOptions.connectingAirport}
                                value={searchOptions.airlineOption ? airlineOptions?.find((option: any) => option.value === searchOptions.airlineOption) as any || { value: undefined, label: (airlines as any)[searchOptions.airlineOption.toUpperCase()]?.name } : { value: undefined, label: 'All Airlines' }}
                                onChange={createFlightOptionOnChange('airlineOption')}
                                options={[{ value: undefined, label: 'All Airlines' }, ...airlineOptions]}
                                placeholder='All Airlines'
                                dropdownIndicator={<CustomCaret fill={!!searchOptions.connectingAirport ? 'grey' : 'white'}/>}
                                styleType={selectStyles.flightSearch}
                                menuPortalTarget={document.body}
                                menuPosition='fixed'
                            />
                        </span>
                    </OverlayTrigger>
                    <Select
                        value={enumeratedSearchOptions.excludeNoCarryOn.find((option: any) => option.value === searchOptions.excludeNoCarryOn) as any}
                        onChange={createFlightOptionOnChange('excludeNoCarryOn')}
                        options={enumeratedSearchOptions.excludeNoCarryOn}
                        dropdownIndicator={<CustomCaret fill='white'/>}
                        styleType={selectStyles.flightSearch}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={searchOptions.airlineOption ? <Tooltip id='tooltip'>
                            <div>You cannot filter by airline and connecting airport at the same time.</div>
                        </Tooltip> : <div/>}
                    >
                        <span>
                            <Select
                                isDisabled={!!searchOptions.airlineOption}
                                value={connectingAirportOptions.find((option: any) => option.value === searchOptions.connectingAirport) as any || { value: undefined, label: 'Connecting Airport' }}
                                onChange={createFlightOptionOnChange('connectingAirport')}
                                options={[{ value: undefined, label: '(Any Connnecting Airport)' }, ...connectingAirportOptions]}
                                placeholder='Connecting Airport'
                                dropdownIndicator={<CustomCaret fill={!!searchOptions.airlineOption ? 'grey' : 'white'}/>}
                                styleType={selectStyles.flightSearch}
                                menuPortalTarget={document.body}
                                menuPosition='fixed'
                            />
                        </span>
                    </OverlayTrigger>
                </OptionsRow>
            </ScrollableOptionsWrapper>
        </OptionsHeader>
        <CalendarItineraryContent>
            <FlightSearchWs
                calendarSearchParams={calendarSearchParams}
                calendarRequestIdToParams={calendarRequestIdToParams}
                calendarResponse={calendarOptions}
                isCalendarLoading={isCalendarLoading}
                setCalendarResponse={setCalendarOptions}
                setFetchedCalendarWeeks={setFetchedCalendarWeeks}
                setErroredCalendarWeeks={setErroredCalendarWeeks}
                setIsCalendarLoading={setIsCalendarLoading}
                itinerarySearchParams={itinerarySearchParams}
                setItinerarySearchData={setItinerarySearchData}
                setItinerarySearchResponse={setItineraryOptions}
                setIsItineraryLoading={setIsItineraryLoading}
                setIsItineraryError={setIsItineraryError}
                itineraryRequestIdToParams={itineraryRequestIdToParams}
                setIsAwardLoading={setIsAwardLoading}
                setIsAwardError={setIsAwardError}
                awardRequestIdToParams={awardRequestIdToParams}
                setIsNdcLoading={setIsNdcLoading}
                setIsNdcError={setIsNdcError}
                ndcRequestIdToParams={ndcRequestIdToParams}
                itinerarySearchResponse={itineraryOptions}
                itinerarySearchData={itinerarySearchData}
                setItinerarySearchSessionId={setItinerarySearchSessionId}
                itinerarySearchSessionId={itinerarySearchSessionId}
                setAwardSearchSessionId={setAwardSearchSessionId}
                awardSearchSessionId={awardSearchSessionId}
                setNdcSearchSessionId={setNdcSearchSessionId}
                ndcSearchSessionId={ndcSearchSessionId}
            />
            <FlightSearchCalendar
                isCalendarLoading={isCalendarLoading}
                fetchCalendar={fetchCalendar}
                calendarOptions={calendarOptions}
                fetchedCalendarWeeks={fetchedCalendarWeeks}
                erroredCalendarWeeks={erroredCalendarWeeks}
                searchOptions={searchOptions}
                updateSearchOption={updateSearchOption}
                selectedItinerary={selectedItinerary}
            />
            <Itineraries
                searchOptions={searchOptions}
                selectedItinerary={selectedItinerary}
                selectItinerary={selectItinerary}
                clearDepartureSelection={clearDepartureSelection}
                isItineraryLoading={isItineraryLoading}
                itineraryOptions={itineraryOptions}
                isItineraryError={isItineraryError}
                isAwardLoading={isAwardLoading}
                isAwardError={isAwardError}
                fetchItineraries={fetchItineraries}
            />
        </CalendarItineraryContent>
        {isSeatMapModalOpen && <SeatMapModal/>}
    </Container>
}

const FadeOverlay = styled.div`
    ${styles.MediaQueries.Desktop} {
        visibility: hidden;
    }
    ${styles.MediaQueries.Mobile} {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to right, rgb(27,34,119,1), rgb(27,34,119,0) 20px, rgb(27,34,119,0) calc(100% - 20px), rgb(27,34,119,1));
        width: 100%;
        height: 60px;
    }
`

const ScrollableOptionsWrapper = styled.div`
    ${styles.Scrollbar.transparent}
    ${styles.MediaQueries.Mobile} {
        overflow-x: scroll;
        overflow-y: visible;
        position: relative;
    }
`

const StyledReverseIcon = styled(Reverse)`
    fill: white;
    height: auto;
    width: 30px;
`

const DatePickerTitle = styled.div`
    ${styles.Text.BodySmall}
    color: white;
    margin-left: ${styles.Spacing.XS};
    text-decoration: underline;
`

const CustomCaret = styled(CaretDown)`
    width: 10px;
    height: 5px;
    margin-right: ${styles.Spacing.XS};
`

const CustomCaretDown = styled(CaretDown)`
    width: 10px;
    height: 5px;
    margin-left: ${styles.Spacing.XS};
`

const DatePickerContainer = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        justify-content: space-between;
        margin: ${styles.Spacing.XS} 0;
    }
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        margin-top: ${styles.Spacing.XS};
        justify-content: end;
    }
`

const MobileDatePickerInput = styled.input`
    ${styles.Text.BodySmall}
    flex: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
    color: white;
    outline: 0;
    height: 30px;
    &::-webkit-date-and-time-value {
        text-align: start;
    }
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
    &:nth-child(n+2) {
        &::-webkit-date-and-time-value {
            text-align: end;
        }
    }
`

const DatePickerDropdownContainer = styled(motion.div)`
    position: relative;
`

const DatePickerInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: ${styles.Spacing.XS};
    padding-left: ${styles.Spacing.S};
    min-width: 280px;
    width: fit-content;
    white-space: nowrap;
    cursor: pointer;
`

const PlaneIcon = styled.div`
    margin-top: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: end;
    outline: 0;
`

const ReverseIcon = styled.div`
    outline: 0;
    cursor: pointer;
    width: 30px;
    ${styles.MediaQueries.Desktop} {
        margin: ${styles.Spacing.XS} ${styles.Spacing.XS} 0 0;
    }
    ${styles.MediaQueries.Mobile} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const FlightLocationDropdownContainer = styled.div`
    position: relative;
    height: min-content;
    ${styles.MediaQueries.Desktop} {
        margin-left: 12px;
    }
    ${styles.MediaQueries.Mobile} {
        flex: 1;
    }
`

const DateAndLocationRow = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${styles.Color.TaekusCream};
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        padding: ${styles.Spacing.XS} ${styles.Spacing.S} 0;
    }
    ${styles.MediaQueries.Desktop} {
        flex: 1;
        flex-wrap: wrap;
    }
`

const OptionsRow = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    ${styles.MediaQueries.Desktop} {
        flex-wrap: wrap;
        overflow: visible;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 20px;
        overflow: visible;
    }
`

const CalendarItineraryContent = styled.div`
    flex: 1;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
        flex-wrap: wrap;
    }
`

const OptionsHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${styles.Color.TaekusBlue};
    color: white;
    ${styles.MediaQueries.Desktop} {
        padding: ${styles.Spacing.XS} ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        position: relative;
        height: min-content;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default Flights