import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import moment from "moment"
import { Img } from "react-image"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import Spinner from "components/common/Spinner"

import { SearchOptions, TicketType, TripType } from "components/pages/Rewards/constants"
import ItineraryOption from "components/pages/Rewards/Flights/FlightSearch/ItineraryOption"
import { buildItineraryPayload } from "components/pages/Rewards/Flights/utils"

import { ReactComponent as Close } from "assets/svg/Close.svg";

import styles from "styles/styles"
import { itineraryPipeline } from "components/pages/Rewards/Flights/ItineraryFilter"

type ItinerariesProps = {
    searchOptions: SearchOptions,
    selectedItinerary: any,
    selectItinerary: (option: any) => void,
    clearDepartureSelection: () => void,
    isItineraryLoading: boolean,
    itineraryOptions: any[],
    isItineraryError: boolean,
    isAwardError: boolean,
    isAwardLoading: boolean,
    fetchItineraries: (params: any, replace: any) => void
}

const Itineraries = (props: ItinerariesProps) => {
    const flightSearch = useSelector((state: any) => state.flightSearch)
    const [processedItineraryOptions, setProcessedItineraryOptions] = useState<any[]>([])
    const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(undefined)

    const shouldShowSelectedItinerary = props.searchOptions.tripType === TripType.RoundTrip && props.selectedItinerary !== undefined && props.selectedItinerary.length > 0

    const getTotalPoints = (itinerary: any) => {
        return itinerary.pricePointsBase + itinerary.pricePointsTaxes
    }

    const isSearchOptionsValid = () => {
        const { searchOptions } = props;

        // Dates are unselected
        if (!searchOptions.departureDate) {
            setValidationErrorMessage('Select a departure date.')
            return false
        }

        if (searchOptions.tripType === TripType.RoundTrip && !searchOptions.returnDate) {
            setValidationErrorMessage('Select a return date.')
            return false
        }

        // Destinations are unselected
        if (!searchOptions.departureAirport || !Object.keys(searchOptions.departureAirport).length) {
            setValidationErrorMessage('Select a departure airport.')
            return false
        }

        if (!searchOptions.arrivalAirport || !Object.keys(searchOptions.arrivalAirport).length) {
            setValidationErrorMessage('Select an arrival airport.')
            return false
        }

        setValidationErrorMessage(undefined)
        return true
    }

    const isOnConfirmationPage = () => {
        const roundTripConfirmationPage = props.searchOptions.tripType === TripType.RoundTrip && props.selectedItinerary?.length === 2
        const oneWayConfirmationPage = props.searchOptions.tripType === TripType.OneWay && props.selectedItinerary
        return oneWayConfirmationPage || roundTripConfirmationPage
    }

    const getErrorMessage = () => {
        switch (flightSearch.nonFieldErrors?.[0]) {
            case "Taekus cannot sell tickets to countries sanctioned by the US government":
                return "Taekus cannot offer tickets for the route you've selected. Please review your options and try again."
            default:
                return validationErrorMessage || 'There was an error fetching flight itineraries. Please review your options and try again.'
        }
    }

    const anyLoading = () => {
        return props.isItineraryLoading || props.isAwardLoading
    }

    const searchErrorExists = () => {
        const cashSearchError = props.searchOptions.ticketType === TicketType.Regular && props.isItineraryError
        const awardSearchError = props.searchOptions.ticketType === TicketType.Award && props.isAwardError
        const allSearchError = props.searchOptions.ticketType === TicketType.All && props.isItineraryError && props.isAwardError
        return cashSearchError  || awardSearchError || allSearchError
    }

    // Fetch itinerary when user selects a date
    useEffect(() => {
        if (isSearchOptionsValid() && !isOnConfirmationPage()) {
            props.fetchItineraries(buildItineraryPayload(props.searchOptions, props.selectedItinerary), true)
        }
    }, [props.searchOptions, props.selectedItinerary]) // eslint-disable-line

    useEffect(() => {
        if (!!props.itineraryOptions.length) {
            const processedItineraries = itineraryPipeline(props.itineraryOptions, props.searchOptions.ticketType)
            setProcessedItineraryOptions(processedItineraries)
        }
         else {
            setProcessedItineraryOptions([])
        }
    }, [props.itineraryOptions]) // eslint-disable-line

    return <ItinerariesContainer>
        <AnimatePresence mode='wait'>
            {shouldShowSelectedItinerary && <SelectedItineraryInfoBlurb {...SelectedItineraryMotionProps}>
                <SelectedItineraryHeader>
                    <SelectedItineraryInfoBlurbTitle>Departure</SelectedItineraryInfoBlurbTitle>
                    <CloseButton onClick={props.clearDepartureSelection}>
                        <StyledClose/>
                    </CloseButton>
                </SelectedItineraryHeader>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                        <LogoContainer>
                            <Logo src={[
                                `/static/img/airlineLogos/mini/${props.selectedItinerary[0].carrierCode.toUpperCase()}.png`,
                                '/static/img/airlineLogos/mini/default.png'
                            ]} />
                        </LogoContainer>
                        <div>{`${props.selectedItinerary[0].slices[0].segments[0].departurePointName} - ${props.selectedItinerary[0].slices[0].segments.at(-1).arrivalPointName}`} (<strong>{getTotalPoints(props.selectedItinerary[0]).toLocaleString()} pts.</strong>)</div>
                    </div>
                    <div>{moment(props.selectedItinerary[0].slices[0].segments[0].localDepartureTimeDate).format('MMM D, YYYY')}</div>
                </div>
            </SelectedItineraryInfoBlurb>}
        </AnimatePresence>
        <ItinerariesHeader>
            <div>
                <ActiveFlightTitle>Choose your {props.searchOptions.tripType === TripType.RoundTrip && props.selectedItinerary?.length === 1 ? 'returning' : 'departing'} flights {props.searchOptions.tripType === TripType.RoundTrip && props.searchOptions.departureDate && props.searchOptions.returnDate && `(${props.searchOptions.returnDate?.diff(props.searchOptions.departureDate, 'days')} day trip)`}</ActiveFlightTitle>
                <FeesDisclaimer>Total price includes taxes + fees for {props.searchOptions.numberOfPassengers} adult{(props.searchOptions.numberOfPassengers !== '1' || '') && 's'}. Additional bag fees and other fees may apply.</FeesDisclaimer>
            </div>
            <ItinerariesTitle>
                {props.searchOptions.tripType === TripType.RoundTrip && props.selectedItinerary?.length === 1 ? 'Returning' : 'Departing'} Flights for {moment(props.searchOptions.tripType === TripType.RoundTrip && props.selectedItinerary?.length === 1 ? props.searchOptions.returnDate : props.searchOptions.departureDate).format('MMMM D')}
            </ItinerariesTitle>
        </ItinerariesHeader>
        <ItinerariesList>
            {(!processedItineraryOptions.length && anyLoading()) ? <FullSizeCenteredContainer>
                <SpinnerContainer>
                    <Spinner/>
                </SpinnerContainer>
            </FullSizeCenteredContainer> : ((!processedItineraryOptions.length && searchErrorExists() && !anyLoading()) ? <FullSizeCenteredContainer><ItineraryError isValidationError={!!validationErrorMessage}>{getErrorMessage()}</ItineraryError></FullSizeCenteredContainer> :
                (processedItineraryOptions.length ? <>
                    {anyLoading() && <div className="d-flex align-items-center">
                        <SmallSpinnerContainer>
                            <Spinner/>
                        </SmallSpinnerContainer>
                        <div> Loading more flights...</div>
                    </div>}
                    {
                    processedItineraryOptions.map((option: any, optionIndex: number) => {
                        return (
                            <ItineraryOption
                                key={optionIndex}
                                isPickingReturnDate={props.searchOptions.tripType === TripType.RoundTrip && props.selectedItinerary?.length === 1}
                                searchOptions={props.searchOptions}
                                onItinerarySelect={props.selectItinerary}
                                options={option}
                            />
                        );
                    })}
                </> : <FullSizeCenteredContainer>
                    <EmptyItinerariesContainer>No flight itineraries are available on this date.</EmptyItinerariesContainer>
            </FullSizeCenteredContainer>)
            )}
        </ItinerariesList>
    </ItinerariesContainer>
}

const SelectedItineraryMotionProps = {
    initial: { height: 0, opacity: 0 },
    exit: { height: 0, opacity: 0 },
    animate: { height: '80px', opacity: 1 },
}

const StyledClose = styled(Close)`
    width: 24px;
    height: 24px;
`

const CloseButton = styled.div`
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    ${styles.Animation.transitionStyles}
`

const SelectedItineraryHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`

const SelectedItineraryInfoBlurbTitle = styled(motion.div)`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    width: min-content;
`

const SelectedItineraryInfoBlurb = styled(motion.div)`
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    border-left: 3px solid ${styles.Color.TaekusPurple};
`

const LogoContainer = styled.div`
    display: flex;
    width: 45px;
    justify-content: center;
`

const Logo = styled(Img)`
    width: auto;
    height: 25px;
    margin-right: ${styles.Spacing.XS};
`

const SpinnerContainer = styled.div`
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
`

const SmallSpinnerContainer = styled.div`
    width: 25px;
    height: 25px;
    margin: ${styles.Spacing.XS};
`

const ItinerariesList = styled.div`
    flex: 1;
    height: 100%;
    ${styles.Scrollbar.defaultScrollbarStyles}
    ${styles.MediaQueries.Desktop} {
        overflow-y: scroll;
        margin-right: 2px;
        max-height: 798px;
    }
`

const FullSizeCenteredContainer = styled.div`
    width: 100%;
    min-height: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
`

type ItineraryErrorProps = {
    isValidationError: boolean;
}

const ItineraryError = styled.div<ItineraryErrorProps>`
    width: 100%;
    margin: ${styles.Spacing.S} 0;
    text-align: center;
    color: ${props => props.isValidationError ? 'black' : 'red'};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    padding: 0 ${styles.Spacing.S};
`

const EmptyItinerariesContainer = styled.div`
    width: 100%;
    margin: ${styles.Spacing.S} 0;
    text-align: center;
    opacity: 0.5;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const ItinerariesHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 140px;
    border-bottom: 1px solid #DFDFDF;
    padding: 0 ${styles.Spacing.S};
`

const ActiveFlightTitle = styled.div`
    font-size: ${styles.Font.Size.Small};
    font-weight: bold;
    margin-top: ${styles.Spacing.XS};
`

const FeesDisclaimer = styled.div`
    font-size: ${styles.Font.Size.Small};
`

const ItinerariesTitle = styled.div`
    margin-bottom: ${styles.Spacing.S};
    ${styles.MediaQueries.Mobile} {
        font-size: 1.2em;
    }
    ${styles.MediaQueries.Desktop} {
        font-size: 24px;    
    }
`

const ItinerariesContainer = styled.div`
    flex: 1;
    max-height: 941px;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 690px;
    ${styles.MediaQueries.Desktop} {
        min-width: 540px;
    }
`

export default Itineraries