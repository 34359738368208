import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from "utils/constants";

import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'
import { Actions as TransferActions } from 'redux/features/banking/transfers'

import Spinner from "components/common/Spinner";
import StatusDisplay from 'components/common/StatusDisplay'

import styles from 'styles/styles'

import { ReactComponent as Checkmark } from "assets/svg/Checkmark.svg";

interface TransferFormData {
    firstName: string;
    lastName: string;
    peerTransferCode: string | null;
    customTransferAmount:  string | null;
    memo: string;
}

type GoBack = () => void;

type TaekusPayConfirmationProps = {
    formData: TransferFormData;
    goBack: GoBack;
}

const TaekusPayConfirmation = (props: TaekusPayConfirmationProps) => {
    const dispatch = useDispatch()

    const banking = useSelector((state: any) => state.banking)    
    const shouldUpdateLinkedAccounts = useSelector((state: any) => state.linkedAccounts.shouldUpdateLinkedAccounts)
    const activeCardAccount = useSelector((state: any) => state.currentUser.currentUser.cardAccounts.find((cardAccount: any) => cardAccount.uuid === banking.account.uuid))
    const peerTransfer = useSelector((state: any) => state.transfers.peerTransfer)

    const [isPaymentSent, setIsPaymentSent] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (shouldUpdateLinkedAccounts){
            dispatch(LinkedAccountActions.getInternalAccounts())
        }
    }, [shouldUpdateLinkedAccounts, dispatch])

    const handlePayFriend = () => {
        const { firstName, lastName, peerTransferCode, customTransferAmount, memo } = props.formData;
        dispatch(TransferActions.createPeerToPeerTransfer({ 
            "firstName": firstName, 
            "lastName": lastName, 
            "peerTransferCode": peerTransferCode, 
            "cardAccountUuid": activeCardAccount.uuid, 
            "amount": customTransferAmount?.replaceAll(',', ''),
            "memo": memo,
            "acceptTerms": true
        }));
    };

    useEffect(() => {
        if (peerTransfer?.success) {
            setIsPaymentSent(true);
            setShowError(false)
            return
        }
        if (peerTransfer?.error) {
            setShowError(true)
            setIsPaymentSent(false);
            return
        }
    }, [peerTransfer])

    return (
        <Container>
            <Description>
                TaekusPay transfers a set amount of money from your Taekus account to any other Taekus user.
            </Description>
            <DescriptionText>You are sending:</DescriptionText>
            <Row>
                <TransferAmount>
                    <div>
                        ${props.formData.customTransferAmount}
                    </div>
                </TransferAmount>
            </Row>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                <FromContainer>
                    <DescriptionText>From:</DescriptionText>
                    <AccountData>
                        <div>
                            {activeCardAccount.name} - {activeCardAccount.availableBalance.toLocaleString(Locale.English, USDCurrencyOptions)}
                        </div>
                    </AccountData>
                </FromContainer>
                <div>
                    <DescriptionText>To:</DescriptionText>
                    <AccountData>
                        <div>
                            {props.formData.firstName} {props.formData.lastName}
                        </div>
                    </AccountData>
                </div>
            </Row>
            {props.formData.memo && <div>
                <DescriptionText>Memo:</DescriptionText>
                <Row>
                    <TransactionDescription>
                        <div>
                            {props.formData.memo}
                        </div>
                    </TransactionDescription>
                </Row>
            </div>}
            <Row>
                <TermsLabel>
                    <div>
                        I authorize Taekus Inc. (Taekus) to initiate a transfer from the account above to the specified recipient.<br/>
                        I certify that I am authorized to initiate this transfer and that it complies with applicable law.<br/>
                        I confirm that this is not a payment for goods or services.
                    </div>
                </TermsLabel>
            </Row>
            {!peerTransfer.isLoading && showError && <StatusDisplay isLoading={false} isError label="Your payment failed. Please try again."/>}
            {!isPaymentSent && !peerTransfer.isLoading && <Row>
                <ButtonsContainer>
                    <ContinueButton onClick={handlePayFriend}>
                        {'Agree & Send'}
                    </ContinueButton>
                    <BackButton onClick={props.goBack}>
                        {'Back'}
                    </BackButton>
                </ButtonsContainer>
            </Row>}
            {peerTransfer.isLoading && <ButtonsContainer>
                <ContinueButton>
                    <SmallSpinnerContainer>
                        <Spinner/>
                    </SmallSpinnerContainer>
                </ContinueButton>
            </ButtonsContainer>}
            {isPaymentSent && <SuccessContainer>
                <CheckmarkContainer>
                    <Checkmark fill='#00bf0c' className='w-100 h-auto'/>
                </CheckmarkContainer>
                <ConfirmationText>Your payment has been sent!</ConfirmationText>
            </SuccessContainer>}
        </Container>
    )
}

const FromContainer = styled.div`
    ${styles.MediaQueries.Desktop}  {
        margin-right: 150px;   
    }
`

const SuccessContainer = styled.div`
    display: flex;
    align-items: center;
    height: min-content;
`

const CheckmarkContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.S};
    display: flex;
    align-items: center;
`

const AccountData = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    padding-top: 10px;
`

const DescriptionText = styled.div`
    font-size: 20px;
    padding-top: 20px;
`

const ConfirmationText = styled.div`
    font-size: 20px;
`

const ButtonsContainer = styled.div`
    margin-top: 16px;
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`
const ContinueButton = styled.div<ContinueButtonProps>`
    cursor: pointer;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.disabled ? '#D7D7D7' : styles.Color.TaekusPurple};
    border-radius: ${styles.BorderRadius.S};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 16px;
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    color: ${props => props.disabled ? '#FFFFFF' : '#FAF8EE'};
    margin-right: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.S};
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const BackButton = styled.div`
    cursor: pointer;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${styles.BorderRadius.S};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 16px;
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    color: ${styles.Color.TaekusPurple};
    margin-right: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.S};
    border: 1px solid ${styles.Color.TaekusPurple};
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Row = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
        margin-bottom: ${styles.Spacing.M};
    }
`

const TransferAmount = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    padding-top: 20px;
`

const TransactionDescription = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 24px;
    line-height: 127%;
    letter-spacing: 0.01em;
    padding-top: 20px;
`

const TermsLabel = styled.label`
    font-style: normal;
    font-size: 14px;
`

const Description = styled.div`
    flex: 0;
    height: min-content;
    align-self: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 ${styles.Spacing.XS};
    font-size: ${styles.Font.Size.Small};
    background-color: #DFDFDF;
    border-radius: 8px;
    padding: ${styles.Spacing.XS};
    margin: ${styles.Spacing.S} 0;
`

const SmallSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
`

type ContinueButtonProps = {
    disabled?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${styles.Spacing.M};
`

export default TaekusPayConfirmation;