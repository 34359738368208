export enum ToggleStatus {
    Internal='INTERNAL',
    Beta='BETA',
    Public='PUBLIC',
}

export enum ToggleFeature {
    ACHRedesign,
    AdvancedTransactionFiltering,
    AuthorizedUsers,
    FlightBookingRedesign,
    Ndc,
    RefundTransactionFiltering,
    Seatmaps,
    TripsRedesign,
}

export const toggles = {
    [ToggleFeature.ACHRedesign]: ToggleStatus.Public,
    [ToggleFeature.AdvancedTransactionFiltering]: ToggleStatus.Public,
    [ToggleFeature.AuthorizedUsers]: ToggleStatus.Public,
    [ToggleFeature.FlightBookingRedesign]: ToggleStatus.Public,
    [ToggleFeature.Ndc]: ToggleStatus.Beta,
    [ToggleFeature.RefundTransactionFiltering]: ToggleStatus.Public,
    [ToggleFeature.Seatmaps]: ToggleStatus.Beta,
    [ToggleFeature.TripsRedesign]: ToggleStatus.Public,
}

const toggleOrder = [
    ToggleStatus.Public,
    ToggleStatus.Beta,
    ToggleStatus.Internal,
]

export const isFeatureEnabled = (userToggleAccess: ToggleStatus, feature: ToggleFeature) => {
    const featureLevel = (toggles as any)[feature]
    return toggleOrder.indexOf(userToggleAccess) >= toggleOrder.indexOf(featureLevel)
}