import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { Locale, USDCurrencyOptions } from 'utils/constants'
import { validateString } from 'utils/utils';

import { Actions as BankingActions } from 'redux/features/banking/banking'

import StatusDisplay from 'components/common/StatusDisplay';

import TaekusPayConfirmation from 'components/pages/Funding/TaekusPayConfirmation';

import styles from 'styles/styles'

interface TransferFormData {
    firstName: string;
    lastName: string;
    peerTransferCode: string | null;
    customTransferAmount: string | null;
    memo: string;
}

type TaekusPayValidationErrors = {
    firstName?: string,
    lastName?: string,
    peerTransferCode?: string,
    customTransferAmount?: string,
    lookup?: string,
}

const defaultValidationErrors: TaekusPayValidationErrors = {
    firstName: undefined,
    lastName: undefined,
    peerTransferCode: undefined,
    customTransferAmount: undefined,
    lookup: undefined,
}

const defaultFormData = {
    firstName: '',
    lastName: '',
    peerTransferCode: null,
    customTransferAmount: null,
    memo: '',
}

const TaekusPay = () => {
    const dispatch = useDispatch()

    // Redux state
    const accountLookup = useSelector((state: any) => state.banking.accountLookup)

    // Component state
    const [isPending, setIsPending] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [formData, setFormData] = useState<TransferFormData>(defaultFormData);
    const [validationErrors, setValidationErrors] = useState(defaultValidationErrors)

    const validationDisplayError = Object.values(validationErrors).find(error => error !== undefined)

    const updateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, firstName: event.target.value }));
    }
    
    const updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, lastName: event.target.value }));
    }
    
    const updatePeerTransferCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? event.target.value : null;
        setFormData(prevState => ({ ...prevState, peerTransferCode: value }));
    };

    const updateCustomTransferAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(event.target.value))) {
            const newValue = event.target.value?.replaceAll(',', '')

            const valueIsANumber = !isNaN(Number(newValue))
            const valueIsInRange = Number(newValue) >= 0 && Number(newValue) < 10000000
            const valueHasUpTo2DecimalPlaces = (!newValue.includes('.') || newValue.split('.')[1]?.length <= 2)

            if (valueIsANumber && valueIsInRange && valueHasUpTo2DecimalPlaces){
                setFormData(prevState => ({ ...prevState, customTransferAmount: newValue }));
            }
        }
    };

    const onTransferAmountBlur = () => {
        const { customTransferAmount } = formData;

        // Add commas back on blur
        const formattedAmount = !customTransferAmount || Number(customTransferAmount.replaceAll(',','')) === 0 ? '' : Number(customTransferAmount.replaceAll(',','')).toLocaleString(Locale.English, USDCurrencyOptions).slice(1)
        setFormData(prevState => ({ ...prevState, customTransferAmount: formattedAmount }));
    }

    const onTransferAmountFocus = () => {
        const { customTransferAmount } = formData;
        
        if (!customTransferAmount || isNaN(Number(customTransferAmount.replaceAll(',', '')))) {
            setFormData(prevState => ({ ...prevState, customTransferAmount: '' }));
        } else {
            // Remove commas while editing value
            setFormData(prevState => ({ ...prevState, customTransferAmount: customTransferAmount.replaceAll(',','') }));
        }
    }

    const updateMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, memo: event.target.value }));
    }

    const handleContinue = () => {
        const updatedErrors = {
            firstName: validateString(formData.firstName) ? undefined : "Recipient first name cannot be empty.",
            lastName: validateString(formData.lastName) ? undefined : "Recipient last name cannot be empty.",
            peerTransferCode: formData.peerTransferCode !== null ? undefined : "Recipient TaekusPay code cannot be empty.",
            customTransferAmount: formData.customTransferAmount === null || Number(formData.customTransferAmount) <= 0 ? "Transfer amount cannot be less than or equal to zero." : undefined,
            lookup: undefined,
        }

        setValidationErrors(updatedErrors)
        if (Object.values(updatedErrors).every(error => error === undefined)) {
            setIsPending(true);
            dispatch(BankingActions.lookupAccount({
                firstName: formData.firstName,
                lastName: formData.lastName,
                peerTransferCode: formData.peerTransferCode
            }));
        }
    };

    useEffect(() => {
        if (accountLookup?.success) {
            setIsConfirmation(true);
            setIsPending(false);
            return
        }
        if (accountLookup?.error) {
            setValidationErrors({
                ...validationErrors,
                lookup: "Cannot find that user. Are you sure you entered the right details?",
            })
            setIsPending(false);
            return
        }
    }, [accountLookup]) // eslint-disable-line

    return (
        <Container>
            {isConfirmation ? <TaekusPayConfirmation formData={formData} goBack={() => setIsConfirmation(false)} /> : (
                <Container>
                    <Description>
                        TaekusPay transfers a set amount of money from your Taekus account to any other Taekus user. Your TaekusPay code to receive funds can be found under Account Info.
                    </Description>
                    {/* {!isMobile && <ClearButtonContainer onClick={clearOptions}>
                            <Close/>
                            <div>Clear all options</div>
                        </ClearButtonContainer>} */}
                    <DescriptionText>You are transferring funds to:</DescriptionText>        
                    <Row>
                        <Name>
                            <div>
                                {<NameInput
                                    onChange={updateFirstName}
                                    value={formData.firstName}
                                />}
                                <Label>{"First Name"}</Label>
                            </div>
                        </Name>
                        <Name>
                            <div>
                                {<NameInput
                                    onChange={updateLastName}
                                    value={formData.lastName} 
                                />}
                                <Label>{"Last Name"}</Label>
                            </div>
                        </Name>
                    </Row>
                    <DescriptionText>with this TaekusPay code:</DescriptionText>
                    <Row>
                        <PeerTransferCode>
                            <div>
                                {<PeerTransferCodeInput
                                    onChange={updatePeerTransferCode}
                                    value={formData.peerTransferCode !== null ? formData.peerTransferCode : ''} 
                                />}
                                <Label>{"TaekusPay Code"}</Label>
                            </div>
                        </PeerTransferCode>
                    </Row>
                    <DescriptionText>in the following amount:</DescriptionText>
                    <Row>
                        <Amount>
                            <div>
                                <div style={{ borderBottom: '1px solid #D9D9D9', display: 'flex' }}>
                                    <span style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }}>$</span>
                                    {<AmountInput
                                        onChange={updateCustomTransferAmount}
                                        value={formData.customTransferAmount ?? ''} 
                                        onBlur={onTransferAmountBlur}
                                        onFocus={onTransferAmountFocus}
                                    />}
                                </div>
                                <Label>{"Amount"}</Label>
                            </div>
                        </Amount>
                        <Memo>
                            <div>
                                <MemoInput
                                    onChange={updateMemo}
                                    value={formData.memo}
                                />
                                <Label>{"Memo (optional)"}</Label>
                            </div>
                        </Memo>
                    </Row>
                    <ErrorDisplayContainer>
                        {validationDisplayError && <StatusDisplay isLoading={false} isError label={validationDisplayError}/>}
                    </ErrorDisplayContainer>
                    <ButtonsContainer>
                        <ContinueButton onClick={() => handleContinue()} disabled={isPending}>
                            {isPending ? 'Processing...' : 'Continue'}
                        </ContinueButton>
                    </ButtonsContainer>
                </Container>
            )}
        </Container>
    );
}

const ErrorDisplayContainer = styled.div`
    min-height: 44px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 20px;
    }
`

const DescriptionText = styled.div`
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
`

const ButtonsContainer = styled.div`
    margin-top: 16px;
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const ContinueButton = styled.div<ContinueButtonProps>`
    cursor: pointer;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.disabled ? '#D7D7D7' : styles.Color.TaekusPurple};
    border-radius: ${styles.BorderRadius.S};
    font-style: normal;
    font-weight: ${styles.Font.Weight[400]};
    font-size: 16px;
    line-height: 138%;
    text-align: right;
    letter-spacing: 0.02em;
    color: ${props => props.disabled ? '#FFFFFF' : '#FAF8EE'};
    margin-right: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.S};
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Row = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
        margin-bottom: ${styles.Spacing.M};
    }
`

const Name = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        padding-right: 20px;
    } 
`

const Amount = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        padding-right: 20px;
    }
`

const PeerTransferCode = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        align-items: center;
        padding-right: 20px;
    }
`

const Memo = styled.div`
    height: ${styles.Spacing.L};
    display: flex;
    flex-direction: column;
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: 28px;
    line-height: 127%;
    letter-spacing: 0.01em;
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        width: 260px;
        align-items: center;
        padding-right: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const NameInput = styled.input`
    color: ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[400]};
    background-color: ${styles.Color.Transparent};
    border: 0;
    border-bottom: 1px solid ${'#D9D9D9'};
    outline: none;
    font-style: normal;
    font-size: 18px;
    line-height: 127%;
    letter-spacing: 0.01em;
    height: ${styles.Spacing.M};
    padding: ${styles.Spacing.XS};
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        width: 260px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const AmountInput = styled.input`
    color: ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[400]};
    background-color: ${styles.Color.Transparent};
    border: 0;
    outline: none;
    font-style: normal;
    font-size: 18px;
    line-height: 127%;
    letter-spacing: 0.01em;
    height: ${styles.Spacing.M};
    padding: ${styles.Spacing.XS};
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        padding-right: 10px;
        width: 260px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const PeerTransferCodeInput = styled.input`
    color: ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[400]};
    background-color: ${styles.Color.Transparent};
    border: 0;
    border-bottom: 1px solid ${'#D9D9D9'};
    outline: none;
    font-style: normal;
    font-size: 18px;
    line-height: 127%;
    letter-spacing: 0.01em;
    height: ${styles.Spacing.M};
    padding: ${styles.Spacing.XS};
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        width: 260px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const MemoInput = styled.input`
    color: ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[400]};
    background-color: ${styles.Color.Transparent};
    border: 0;
    border-bottom: 1px solid ${'#D9D9D9'};
    outline: none;
    font-style: normal;
    font-size: 18px;
    line-height: 127%;
    letter-spacing: 0.01em;
    height: ${styles.Spacing.M};
    border-radius: 0;
    ${styles.MediaQueries.Desktop} {
        width: 260px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Label = styled.div`
    font-weight: ${styles.Font.Weight[400]};
    color: ${styles.Color.NearBlack};
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    opacity: 0.5;
    padding-top: 4px;
    padding-left: 5px;
`

const Description = styled.div`
    flex: 0;
    height: min-content;
    align-self: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 ${styles.Spacing.XS};
    font-size: ${styles.Font.Size.Small};
    background-color: #DFDFDF;
    border-radius: 8px;
    padding: ${styles.Spacing.XS};
    margin: ${styles.Spacing.S} 0;
`

type ContinueButtonProps = {
    disabled?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${styles.Spacing.M};
`

export default TaekusPay;