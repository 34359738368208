import { Moment } from "moment"
import React from "react"

import { default as ReactDatePicker } from "react-datepicker"

import 'assets/global/css/reactDatePicker.scss'

type DatePickerProps = {
    selected: Moment | undefined,
    startDate: Moment | undefined,
    onChange: (date: any) => void,
    customInput: React.ReactNode,
    endDate?: Moment | undefined,
    minDate?: Moment,
    maxDate?: Moment,
    selectsRange?: boolean,
    id?: string,
}

const DatePicker = (props: DatePickerProps) => {
    const renderDaysContents = (day: number) => <span>{day}</span>

    return <div className='datePicker'>
        <ReactDatePicker
            id={props.id}
            selected={props.selected?.toDate()}
            startDate={props.startDate?.toDate()}
            endDate={props.endDate?.toDate()}
            onChange={props.onChange}
            customInput={props.customInput}
            minDate={props.minDate?.toDate()}
            maxDate={props.maxDate?.toDate()}
            selectsRange={props.selectsRange}
            disabledKeyboardNavigation
            renderDayContents={renderDaysContents}
            popperProps={{strategy: 'fixed'}}
        />
    </div>
}

export default DatePicker